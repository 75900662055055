html {
  /* background: #000; */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
* {
  font-family: "Poppins";
}
body {
  /* background: linear-gradient(142deg, rgb(10, 10, 20) 60%, rgb(20, 20, 30) 40%); */
  /* background-color: black; */
  /* background: linear-gradient(black, black); */
  /* background: linear-gradient(142deg, rgb(10, 10, 20), rgb(10, 10, 30)); */
  /* min-height: 100%;
  height: 100%; */
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}

::-webkit-scrollbar {
  display: none;
}

.noLandscapeMode {
  display: none;
}

@media screen and (max-height: 500px) {
  @media (min-aspect-ratio: 10/7) {
    .noLandscapeMode {
      display: block;
    }
  }
}

.container {
  position: relative;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(1.1);
  transition: opacity 300ms, transform 300ms;
}

/* landing scrollabar */
/* width */
@media screen and (min-width: 600px) {
  .ScrollBar::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
}
/* Track */
.ScrollBar::-webkit-scrollbar-track {
  background: #eee;
}

.ScrollBar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
.ScrollBar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
